<template>
  <div class="page-wrap">
    <div class="session-form-hold">
      <template v-if="error">
        <v-alert
          class="mb-4"
          dismissible
          type="error"
        >
          {{ error }}
        </v-alert>
      </template>
      <base-card>
        <v-card-text class="text-center">
          <h5>Lets get started</h5>
          <h6 class="text--disabled font-weight-medium mb-10">
            Create an account to use the Portal
          </h6>
          <v-form
            ref="registrationFrom"
            v-model="valid"
            lazy-validation
          >
            <v-stepper
              v-model="e1"
              elevation="0"
            >
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-text-field
                    v-model="newUser.email"
                    label="Email"
                    required
                    :rules="emailRules"
                  />

                  <v-text-field
                    v-model="newUser.password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    name="input-10-2"
                    label="Password"
                    :rules="passwordRules"
                    required
                    @click:append="show = !show"
                  />
                  <v-text-field
                    v-model="confirmPassword"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    name="input-10-2"
                    label="Confirm Password"
                    :rules="passwordRules"
                    required
                    @click:append="show = !show"
                  />
                  <v-checkbox
                    v-model="newUser.accepts_terms"
                    :rules="requiredRules"
                    :value="true"
                    label="I have read and agree to the terms of service."
                  />
                  <v-btn
                    class="mb-4"
                    block
                    color="primary"
                    dark
                    @click="e1++"
                  >
                    Next
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="2">
                  {{ newImage }}
                  <v-text-field
                    v-model="newUser.contact.phone"
                    class="mt-4"
                    label="Mobile Or Phone Number"
                    :rules="requiredRules"
                    type="number"
                    outlined
                  />
                  <div>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :rules="requiredRules"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="newUser.contact.dob"
                          label="Date of Birth"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="newUser.contact.dob"
                        label="Date of Birth"
                        :active-picker.sync="activePicker"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1930-01-01"
                        @change="save"
                      />
                    </v-menu>
                  </div>
                  <v-text-field
                    v-model="newUser.contact.givenName"
                    label="Legal Firstname"
                    :rules="requiredRules"
                    required
                    outlined
                  />
                  <v-text-field
                    v-model="newUser.contact.surname"
                    label="Legal Surname"
                    :rules="requiredRules"
                    required
                    outlined
                  />
                  <v-file-input
                    v-model="newImage"
                    show-size
                    counter
                    multiple
                    outlined
                    required
                    label="Attach a headshot"
                  />
                  <v-btn
                    block
                    class="mr-4 mb-2 elevation-0"
                    @click="e1--"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="info"
                    block
                    class="mr-4"
                    submit
                    @click="e1++"
                  >
                    next
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="3">
                  <v-text-field
                    v-model="newUser.location.address_line_1"
                    label="Address Line 1"
                    :rules="requiredRules"
                    required
                    outlined
                  />
                  <v-text-field
                    v-model="newUser.location.address_line_2"
                    label="Address Line 2"
                    required
                    outlined
                  />
                  <v-text-field
                    v-model="newUser.location.suburb"
                    label="Suburb"
                    :rules="requiredRules"
                    required
                    outlined
                  />
                  <v-select
                    v-model="newUser.location.state"
                    :items="registerActiveStates"
                    item-text="name"
                    item-value="_id"
                    label="State"
                    :rules="requiredRules"
                    required
                    outlined
                  />
                  <v-text-field
                    v-model="newUser.location.postcode"
                    label="Postcode"
                    type="number"
                    :rules="requiredRules"
                    required
                    outlined
                  />
                  <v-checkbox
                    v-model="newUser.location.isInternational"
                    :false-value="false"
                    :true-value="true"
                    label="I am an international registrant."
                  />
                  <v-btn
                    block
                    class="mr-4 mb-2 elevation-0"
                    @click="e1--"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="info"
                    block
                    class="mr-4"
                    submit
                    @click="e1++"
                  >
                    next
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <template v-if="!loading">
                    <v-checkbox
                      v-model="consentCheckbox"
                      required
                      label="While a financial member of this SCCA State/Territory Association, I hereby agree to submit myself to a breath, blood, urine or saliva test at any time at the request of the Controlling Body governing my division of speedway racing.
I understand that if required to undergo a breath, blood, urine or saliva test it is for the purpose of determining whether I have consumed any substance prohibited by the World Anti Doping Authority and by the Controlling Body governing my division of speedway racing.
I understand and acknowledge that if I refuse to undergo a test when called upon to do so or substitute/tamper with a sample, then I am deemed to have consumed a prohibited substance and will be subject to such penalty/penalties as are prescribed by the Controlling Body.
I agree that it is my responsibility to make myself aware of the Rules, Regulations and Policies relating to both alcohol and drugs as set by the Controlling Body governing my division of speedway racing.
"
                    />
                    <v-btn
                      block
                      class="mr-4 mb-2 elevation-0"
                      @click="e1--"
                    >
                      Back
                    </v-btn>
                    <v-btn
                      color="info"
                      block
                      class="mr-4"
                      submit
                      :disabled="!valid || !consentCheckbox"
                      @click="submitRegistration"
                    >
                      Submit
                    </v-btn>
                  </template>
                  <template v-if="loading">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mb-4"
                    />
                  </template>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-form>
          <div class="d-flex justify-around flex-wrap">
            <v-btn
              text
              small
              color="primary"
              to="/login"
            >
              Login to an existing account
            </v-btn>
          </div>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'Register',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Registrant Registrantion',
    },
    data () {
      return {
        newImage: null,
        states: [
          { id: 1, name: 'VIC' },
          { id: 2, name: 'NSW' },
        ],
        newUser: {
          role: 'Registrant',
          contact: {},
          location: {
            country: 'Australia',
          },
        },
        e1: 1,
        show: false,
        email: '',
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          v => !!v || 'Password is required',
        ],
        phoneNumberRules: [
          v => !!v || 'Mobile/PHone number is required',
        ],
        requiredRules: [
          v => !!v || 'This field is required',
        ],
        confirmPassword: '',
        checkbox1: true,
        checkbox2: false,
        consentCheckbox: false,
        activePicker: null,
        date: null,
        menu: false,
        valid: false,
      }
    },
    computed: {
      ...mapGetters(['error', 'loading', 'registerActiveStates']),
    },
    watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },

      consentCheckbox (val) {
        if (val === true) {
          this.$refs.registrationFrom.validate()
        }
      },
    },
    created () {
      this.registerGetStates()
    },
    methods: {
      ...mapActions(['register', 'registerGetStates']),
      save (date) {
        this.$refs.menu.save(date)
      },
      submitRegistration () {
        if (this.$refs.registrationFrom.validate()) {
          // preferred name added here
          this.newUser.contact.preferredName = `${this.newUser.contact.givenName} ${this.newUser.contact.surname}`
          this.register(this.newUser)
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
.theme--dark.v-stepper {
  background: #1e1e1e;
}
</style>
